var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { SpecialTransferDataService } from "~/services/repair-service/special-transfer-data.service";
import AddSpecialData from "~/components/data-repair/special-data/add-special-data.vue";
import EditSpecialData from "~/components/data-repair/special-data/edit-special-data.vue";
import CreatedSpecialData from "~/components/data-repair/special-data/created-special-data.vue";
import SpecialDetail from "~/components/data-repair/special-data/special-detail.vue";
import RepairDataImport from "~/components/data-repair/repair-data-import.vue";
import { DataRepairImportService } from "~/services/repair-service/data-repair-import.service";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import ImportBatchDeletion from "~/components/data-repair/census-data/import-batch-deletion.vue";
import { dataRepair } from "~/config/enum.config";
//@Auth(105)
var SpecialData = /** @class */ (function (_super) {
    __extends(SpecialData, _super);
    function SpecialData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.rowData = {};
        _this.importType = "SPECIAL_RESOURCE"; //特调资料
        _this.createdModel = {};
        _this.censusModel = {};
        _this.editModel = {};
        _this.dataSet = [];
        _this.currentType = dataRepair;
        _this.kosekiModel = {
            id: "",
            name: "",
            idNo: "",
            bank: "",
            type: "",
            applyTransferArea: "",
            remark: "",
            importType: "",
            flag: "",
            credentialSet: [],
            applyFileContent: ""
        };
        _this.data = {};
        _this.typeSet = [];
        _this.dialog = {
            detail: false,
            edit: false,
            created: false,
            import: false,
            deletion: false
        };
        return _this;
    }
    SpecialData.prototype.created = function () { };
    SpecialData.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 关闭导入
     */
    SpecialData.prototype.cancelData = function () {
        this.dialog.import = false;
        this.kosekiModel.flag = null;
    };
    /**
     * 获取类型
     */
    SpecialData.prototype.getDataType = function () {
        var _this = this;
        this.loading.state = true;
        this.kosekiModel.importType = this.importType;
        this.dataRepairImportService
            .getDataTypes(this.kosekiModel, this.loading)
            .subscribe(function (data) {
            _this.typeSet = data;
        });
        this.kosekiModel.importType = null;
    };
    /**
     * keep-alive生命周期钩子函数
     */
    SpecialData.prototype.activated = function () {
        // 加载数据
        this.refreshData();
    };
    /**
     * 查询户籍数据
     */
    SpecialData.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.specialTransferDataService
            .search(this.kosekiModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
        this.getDataType();
    };
    /**
     * 获取下载模板
     */
    SpecialData.prototype.downLoadTemplate = function () {
        var _this = this;
        this.kosekiModel.applyFileContent = this.importType;
        this.dataRepairImportService
            .getSystemTemplate(this.kosekiModel, this.loading)
            .subscribe(function (data) {
            if (data) {
                _this.uploadFileService
                    .getFileStreamById(data.value)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
        this.kosekiModel.applyFileContent = null;
    };
    //添加户籍弹窗
    SpecialData.prototype.addClick = function () {
        this.dialog.detail = true;
    };
    SpecialData.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    SpecialData.prototype.dataImportClick = function () {
        this.dialog.import = true;
        this.kosekiModel.flag = "1";
    };
    /**
     * 查看特殊资料详情
     */
    SpecialData.prototype.viewDetails = function (row) {
        this.rowData = row;
        this.dialog.special = true;
    };
    /**
     * 删除户籍数据
     */
    SpecialData.prototype.deleteKoseki = function (scope) {
        var _this = this;
        this.$confirm("您确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.loading.state = true;
            _this.kosekiModel.id = scope.row.id;
            _this.specialTransferDataService
                .delete(_this.kosekiModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshData();
            });
        }, function (err) {
            _this.$message.error(err.msg);
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消删除"
            });
        });
    };
    /**
     * 修改户籍弹框
     */
    SpecialData.prototype.editKoseki = function (scope) {
        this.editModel = scope.row;
        this.dialog.edit = true;
    };
    /**
     * 添加计生资料
     */
    SpecialData.prototype.createdKoseki = function (scope) {
        this.createdModel = scope.row;
        this.dialog.created = true;
    };
    SpecialData.prototype.importBatchDeletion = function () {
        this.dialog.deletion = true;
    };
    __decorate([
        Dependencies(PageService)
    ], SpecialData.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], SpecialData.prototype, "sortService", void 0);
    __decorate([
        Dependencies(SpecialTransferDataService)
    ], SpecialData.prototype, "specialTransferDataService", void 0);
    __decorate([
        Dependencies(DataRepairImportService)
    ], SpecialData.prototype, "dataRepairImportService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], SpecialData.prototype, "uploadFileService", void 0);
    SpecialData = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddSpecialData: AddSpecialData,
                EditSpecialData: EditSpecialData,
                CreatedSpecialData: CreatedSpecialData,
                RepairDataImport: RepairDataImport,
                SpecialDetail: SpecialDetail,
                ImportBatchDeletion: ImportBatchDeletion
            }
        })
    ], SpecialData);
    return SpecialData;
}(Vue));
export default SpecialData;
